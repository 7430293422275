import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../contexts/LocaleContext';
import { getContactInfo } from '../../js/api/contactInfo';
import Icon from './Icon';
import ContactInfoData from '../../models/ContactInfo';

/**
 *
 * @param {{layout: 'wide' | 'narrow', theme: 'dark' | 'light'}} props
 * @returns
 */

export default function ContactInfo({ layout = 'wide', theme = 'light', className = '' }) {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage', 'ContactPage']);
  const [contactInfo, setContactInfo] = useState(null);
  const whatsappLinkPrefix = 'http://wa.me/';

  function removeWhitespace(string) {
    return string.replace(/ /g, '');
  }

  /**
   * Handle fetching contact info data.
   */
  useEffect(() => {
    getContactInfo()
      .then((res) => {
        setContactInfo(new ContactInfoData(res?.data));
      })
      .catch((e) => {
        toast.error(t('contactInfoError'));
        throw e;
      });
  }, [locale]);

  return (
    <div className={`contact-info ${layout} ${className}`}>
      <div className="contact-info__list">
        <a
          href={`${
            layout === 'wide'
              ? `tel:${contactInfo?.phone_number}`
              : `${whatsappLinkPrefix}${removeWhitespace(contactInfo?.phone_number || '')}`
          }`}
          className="info__link">
          <Icon
            name={`${layout === 'wide' ? 'phone' : 'whats-app-symbol'}`}
            className="info__icon"
            stroke={theme === 'dark' ? '#fff' : '#262452'}
          />
          <span className="phone-number">{contactInfo?.phone_number}</span>
          {layout === 'narrow' && (
            <span className="send-whatsapp">{t('sendONWhatsapp', { ns: 'ContactPage' })}</span>
          )}
        </a>

        {layout === 'wide' && (
          <a href={`mailto:${contactInfo?.email_address}`} className="info__link">
            <Icon
              name="open-envelope"
              className="info__icon"
              stroke={theme === 'dark' ? '#fff' : '#262452'}
            />
            <span>{contactInfo?.email_address}</span>
          </a>
        )}

        <p className="info__link address">
          <Icon
            name="location-pin"
            className="info__icon"
            stroke={theme === 'dark' ? '#fff' : '#262452'}
          />
          <span>{contactInfo?.address}</span>
        </p>

        <p className="info__link working-hours">
          <Icon
            name="calendar"
            className="info__icon"
            stroke={theme === 'dark' ? '#fff' : '#262452'}
          />
          <span>{contactInfo?.working_hours}</span>
        </p>
      </div>

      <ul className="social-links">
        {layout === 'narrow' && (
          <span className="follow-us decorated-line decorated-line--dark decorated-line--right">
            {t('followUs', { ns: 'ContactPage' })}
          </span>
        )}
        <li className="social-links__link">
          <a href={`${contactInfo?.facebook_link}`} target="_blank" rel="noreferrer">
            <Icon name="facebook-app-symbol" stroke={theme === 'dark' ? '#fff' : '#262452'} />
          </a>
        </li>
        <li className="social-links__link">
          <a href={`${contactInfo?.linkedin_link}`} target="_blank" rel="noreferrer">
            <Icon name="linkedin" stroke={theme === 'dark' ? '#fff' : '#262452'} />
          </a>
        </li>
        <li className="social-links__link">
          <a href={`${contactInfo?.instagram_link}`} target="_blank" rel="noreferrer">
            {layout === 'wide' ? (
              <Icon name="instagram" stroke={theme === 'dark' ? '#fff' : '#262452'} />
            ) : (
              <Icon name="instagram-dark" />
            )}
          </a>
        </li>
        {/* {layout === 'wide' && (
          <li className="social-links__link">
            <a href={`${contactInfo?.whatsapp_link}`} target="_blank" rel="noreferrer">
              <Icon name="whats-app-symbol" stroke={theme === 'dark' ? '#fff' : '#262452'} />
            </a>
          </li>
        )} */}
      </ul>
    </div>
  );
}
