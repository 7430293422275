import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from './Logo';
import SideMenu from './SideMenu';
import Navbar from '../Navbar/Navbar';
import Icon from '../common/Icon';
import EnglishLogo from './EnglishLogo';
import scrollToTarget from '../../js/utils/scroll';

export default function Header() {
  const { t } = useTranslation(['HomePage']);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const location = useLocation();

  /**
   * Toggle mobile navigation menu.
   */
  const toggleMobileMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
    document.body.classList.toggle('overflow-hidden');
  };

  function scrollDown(elementId) {
    const element = document?.getElementById(elementId);
    scrollToTarget(element);
  }

  useEffect(() => {
    if (mobileMenuIsOpen) {
      toggleMobileMenu();
    }

    const currentRoute = location.pathname.split('/').pop();
    setIsLandingPage(currentRoute === 'admission-campaign');
  }, [location]);

  return (
    <header className="page-header">
      {!isLandingPage ? <Logo /> : <EnglishLogo />}

      <button
        type="button"
        className="button menu-button"
        title="open navigation menu"
        onClick={toggleMobileMenu}>
        <span>{t('menu')}</span>
        <Icon name="menu-icon" />
      </button>

      {mobileMenuIsOpen ? <SideMenu toggleMobileMenu={toggleMobileMenu} /> : null}

      <Navbar />

      {isLandingPage ? (
        <button
          type="button"
          className="button apply-now"
          title="apply now"
          onClick={() => scrollDown('contact-form')}>
          <Icon name="open-light" />
          <span>{t('getInTouch')}</span>
        </button>
      ) : (
        <a
          href="https://manaraschool.heiapply.com/application/home"
          target="_blank"
          className="button apply-now"
          title="apply now"
          rel="noreferrer">
          <Icon name="open-light" />
          <span>{t('applyNow')}</span>
        </a>
      )}
    </header>
  );
}
