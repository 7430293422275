import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navbar from '../Navbar/Navbar';
import Logo from './Logo';
import EnglishLogo from './EnglishLogo';
import Icon from '../common/Icon';
import scrollToTarget from '../../js/utils/scroll';

export default function SideMenu({ toggleMobileMenu }) {
  const { t } = useTranslation(['HomePage']);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname.split('/').pop();
    setIsLandingPage(currentRoute === 'admission-campaign');
  }, [location]);

  function scrollDown(elementId) {
    toggleMobileMenu();

    const element = document?.getElementById(elementId);
    scrollToTarget(element);
  }

  return (
    <div className="side-menu">
      <header>
        {!isLandingPage ? <Logo /> : <EnglishLogo />}

        <button
          type="button"
          className="button close-button"
          title="close navigation menu"
          onClick={toggleMobileMenu}>
          <Icon name="close" />
        </button>
      </header>

      <Navbar display="column" />

      {isLandingPage ? (
        <button
          type="button"
          className="button apply-now"
          title="apply now"
          onClick={() => scrollDown('contact-form')}>
          <Icon name="open-light" />
          <span>{t('getInTouch')}</span>
        </button>
      ) : (
        <a
          href="https://manaraschool.heiapply.com/application/home"
          target="_blank"
          className="button apply-now"
          title="apply now"
          rel="noreferrer">
          <Icon name="open-light" />
          <span>{t('applyNow')}</span>
        </a>
      )}
    </div>
  );
}
